/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import encode from './encode';
import { navigate } from 'gatsby-link';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ContactForm() {
  // @ts-ignore
  const classes = useStyles();
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };
  return (
    <Container component="main" maxWidth="sm" id="ContactForm">
      <h2
        sx={{
          fontSize: ['24px', '32px', '48px'],
          textAlign: 'center',
        }}
      >
        Ask A Question
      </h2>

      <form
        className={classes.form}
        name="contact"
        method="post"
        action="/thankyou"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="company"
              label="Company Name"
              name="company"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="phone"
              name="phone"
              variant="outlined"
              required
              fullWidth
              id="phone"
              label="Phone Number"
              type="phone"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              multiline
              rows="4"
              rowsMax="30"
              name="message"
              label="What are you looking for?"
              placeholder="Try to provide some background."
              id="message"
              onChange={handleChange}
            />
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <FormControlLabel*/}
          {/*    control={*/}
          {/*      <Checkbox value="allowExtraEmails" color="primary" name="solicit" />*/}
          {/*    }*/}
          {/*    label="I want to receive marketing promotions and updates via email."*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Submit
        </Button>
      </form>
    </Container>
  );
}
